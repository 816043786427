
// Define multiple base URLs as an object
const BASE_URLS = {

  // Server link
  main: 'https://rathin.ae/webadmin/api/',
  webUrl: '/',
  
  payUrl: 'https://rathin.ae/',
  pk_live: 'pk_live_taTrCghvKeHz576b9IfcEopd',
  tapApiUrl: "https://api.tap.company/v2",

};
export default BASE_URLS;
