// AvailableDatesModal.js
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const AvailableDatesModal = ({ enabledDates, showCalendar, onHide, onDateSelected, selItem, handleSelect }) => {
  const [startDate, setStartDate] = useState(null);
  const minDate = new Date();
  minDate.setDate(minDate.getDate());

  const handleApplyClick = () => {
    if (selItem) {
      handleSelect(selItem);
      onHide();
    } else {
      if (startDate) {
        onDateSelected(startDate);
        onHide();
      }
    }
  };
  // const enabledDates = ['2023-12-10', '2023-12-15', '2023-12-20'];
  // Function to check if a date is within the specified enabled dates
  const isDateEnabled = (date) => {
    return enabledDates.some((enabledDate) => {
      return date.getTime() === new Date(enabledDate).getTime();
    });
  };
  // console.log(enabledDates);
  return (
    <>
      <Modal
        show={showCalendar}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="calendar"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select a date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            renderCustomHeader={({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
              <div>
                <button
                  aria-label="Previous Month"
                  className={
                    `react-datepicker__navigation react-datepicker__navigation--previous ${
                      customHeaderCount === 1 ? 'invisible' : ''
                    }`
                  }
                  onClick={decreaseMonth}
                >
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                    {"<"}
                  </span>
                </button>
                <span className="react-datepicker__current-month">
                  {monthDate.toLocaleString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <button
                  aria-label="Next Month"
                  className={
                    `react-datepicker__navigation react-datepicker__navigation--next ${
                      customHeaderCount === 0 ? 'invisible' : ''
                    }`
                  }
                  onClick={increaseMonth}
                >
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                    {">"}
                  </span>
                </button>
              </div>
            )}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              onDateSelected(date);
            }}
            monthsShown={2}
            inline
            minDate={minDate}
            includeDates={enabledDates && enabledDates.map ? enabledDates.map((date) => new Date(date)) : []}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleApplyClick} disabled={!startDate}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
