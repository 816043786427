import React, { useState, useEffect } from 'react';
import CouponProduct from '../Home/CouponProduct';

export const AvailableDate = ({ onDateSelected, openCalendarModal, proData, enabledDates }) => {
  const [showMobileComponent, setShowMobileComponent] = useState(false);
  const [datesAndDays, setDatesAndDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setShowMobileComponent(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (proData.tick_type === 3) {
      const dateNew = new Date();
      const dateOptions = {
        day: '2-digit',
        month: 'short',
      };
      const formattedDateCurr = new Intl.DateTimeFormat('en-US', dateOptions).format(dateNew);
      const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(dateNew);
      setSelectedDate(formattedDateCurr);
      onDateSelected(formattedDate);
    }
  }, [proData.tick_type]);

  useEffect(() => {
    const calculateDatesAndDays = () => {
      const datesAndDaysArray = [];
      if (enabledDates && Array.isArray(enabledDates)) {
        for (let i = 0; i < Math.min(7, enabledDates.length); i++) {
          const date = new Date(enabledDates[i]);
          const dateE = new Date(enabledDates[i]);
          date.setDate(dateE.getDate());
          const dayOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };
          const dateOptions = {
            day: '2-digit',
            month: 'short',
          };
          const dateOptionsYr = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          };
          const day = new Intl.DateTimeFormat('en-US', dayOptions).format(date);
          const formattedDateYr = new Intl.DateTimeFormat('en-US', dateOptionsYr).format(date);
          const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);

          datesAndDaysArray.push({ day, formattedDate, formattedDateYr });
        }

        setDatesAndDays(datesAndDaysArray);
      }
    };

    calculateDatesAndDays();
  }, [enabledDates]);

  // Function to handle the selection of a date
  const handleDateSelection = (formattedDate) => {
    setSelectedDate(formattedDate);
    onDateSelected(formattedDate);
  };

  return (
    <>
      <div className='order-lg-1 order-sm-2 mb-2 pb-3' id="next-dates-coupon">
        <CouponProduct proID={proData.products_id}/>
      </div>
      {proData.tick_type !== 3 && (
        <div className="col-lg-12 order-lg-2 order-sm-1" id="next-dates">
          <div className="date-selection order-sm-1" data-aos="fade-up" data-aos-delay="200">
            <h3 className=" mt-4 mb-3 mt-sm-3">Next available dates</h3>
            <div className="input-radio-date">
              {datesAndDays.map((item, index) => (
                <React.Fragment key={index}>
                  {showMobileComponent || index < 4 ? (
                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                        id={`option${index + 1}`}
                        autoComplete="off"
                        onChange={() => handleDateSelection(item.formattedDateYr)}
                        checked={item.formattedDateYr === selectedDate}
                      />
                      <label className="btn btn-secondary" htmlFor={`option${index + 1}`}>
                        <span>{item.formattedDate}</span>
                        <span className="day">{item.day.slice(0, 3)}</span>
                      </label>
                    </>
                  ) : null}
                </React.Fragment>
              ))}
              <label className="btn btn-secondary" onClick={() => openCalendarModal(null)}>
                <i className="bi bi-calendar"></i>
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
